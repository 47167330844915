import React from 'react'


function Test() {
    return (
        <div className='bg-[#121212] flex flex-col justify-center items-center gap-10 h-screen'>
                <h2 className='text-white text-2xl font-medium text-animation'>Test Mode OFF</h2>
        </div>
    )
}

export default Test